import React, { useState } from "react";
import "./Boxes.css";
import Attendence from "../../image/web5.d5cc0ac54de9bfe8b506.webp";
import grades from "../../image/box2Main.webp";
import Records from "../../image/box3.webp";
import AttendenceSub from "../../image/box1side.png";
import RecordSub from "../../image/Box3sub.webp";
import Grades from "../../image/Box2.png";
import Parent from "../../image/parent-meeting.webp";
import axios from "axios";
import token from "../../../Config/Token";

const Boxes = ({

  qution = [
    {
      question: "Why we should use My school Campus software?",
      answer:
        "Experience streamlined workflow, enhanced communication, data-driven decision making, improved student appointment, and empowered parent involvement. Say goodbye to paperwork load and hello to increased efficiency and a thriving school community.",
    },
    {
      question: "Is My School Campus user friendly software and Easy to learn for a end users?",
      answer:
        "My School Campus is designed with simplicity in mind, ensuring that users can easily navigate and utilize its features. The intuitive interface allows even those with limited technical knowledge to quickly learn and manage their tasks effectively.",
    },
    {
      question: "What is the cost of My School Campus software?",
      answer:
        "The cost of My School Campus varies depending on the features and scale required by your institution. We offer flexible pricing plans to accommodate different needs, ensuring affordability without compromising on functionality.",
    },
    {
      question:
        " Is my data safe and secure with My School Campus software?",
      answer:
        "Your data’s security is our top priority. My school Campus ensures data encryption and security measures to protect your information, whether its online or offline database we always encrypt data with 2- step verification. We ensure compliance with the latest data protection regulations to safeguard your school’s data.",
    },
    
  ],

}) => {
  const data = [
    {
      heading: "Track student attendance",
      description:
        "My School Campus makes it easy to track student attendance with a variety of features, including online check-in, automatic notifications, and reporting.",
      backimage: Attendence,
      subimage: AttendenceSub,
    },
    {
      heading: "Manage student grades",
      description:
        " My School Campus provides a powerful grade book that makes it easy to track student progress, assign grades, and generate reports.",
      backimage: grades,
      subimage: Grades,
    },
    {
      heading: "Store student records",
      description:
        "My School Campus stores all student records in a secure, centralized database. This includes grades, attendance, contact information, health information, achievements, teacher remarks, and parent communication.",
      backimage: Records,
      subimage: RecordSub,
    },
    {
      heading: "Communicate with parents",
      description:
        "My School Campus provides a variety of tools to help you communicate with parents. These tools include a messaging system, a calendar, and a feedback system.",
      backimage: Parent,
      
    },
  ];

  const [Emaildata, setEmaildata] = useState({
    name:"",
    email:"",
    mobileno:"",
    interestedProduct:"",
    businessname:"",
    requirment:""
  })

  const [openSection, setOpenSection] = useState(null);

  const handleChagne = (e)=>{
    debugger
    const {name, value} = e.target
    setEmaildata({
      ...Emaildata,
      [name]:value
    })
  }
  
    // Function to toggle dropdown sections
    const toggleDropdown = (section) => {
      setOpenSection(openSection === section ? null : section);
    };

  const handleFormSubmmit = async(e)=>{
    try{
      debugger
      const send = await axios.post(`https://www.websitebackend.yes-bm.com/email/sendmail`,{
        "text":`
        name:${Emaildata.name},
        email:${Emaildata.email},
        mobileno:${Emaildata.mobileno},
        interestedproduct:${Emaildata.interestedProduct},
        Business Name:${Emaildata.businessname},
        Requirement:${Emaildata.requirment}
        `,
        "subject":"Techcherry Education",
        "to":"technical.techcherry@gmail.com"
      },{
        headers:{
          Authorization:`Bearer ${token}`
        }
      })

      if(send.status==200){
        alert(`message sent successfully`)
      }
    }catch(error){
      console.log(error)
    }
  }
  
  return (
    <div>
      <div className={`boxes-div`}>
        <h3 className="school-campur-head">My School Campus is a powerful school management system that can help you improve your school’s efficiency and effectiveness. With My School Campus, you can:</h3>
        <div className="flex-box-img-edu">
          {data.map((item, index) => {
            return (
              <>
                <div className={`single-div w-[180px] h-[400px]`}>
                  <div
                    style={{ backgroundImage: `url(${item.backimage})` }}
                    className={`single-div-image-div`}
                  >
                    <img src={item.subimage} />
                  </div>

                  <div className={`text-div`}>
                    <h3>{item.heading}</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>


      <div className="flex-ERP-Demo">
        <div className="left-edu-demo">
          <div className="left-content-edu">Why YES-IM is Best Education Software?</div>
          <div className="our-solution-edu">Our solutions includes admission, billing, student apps, library services, HR, LMS and inventory management.</div>
          <div className="expertise-efficient-edu">With over 15 years of expertise, we ensure efficient, user-friendly and innovative software to transform your Institution’s operations.</div>

        </div>
        <div className="right-edu-demo">
          <div>
            <h2 className="erp-software-demo">ERP Software Demo</h2>
          </div>
          <div>
            <h2 className="book-demo-now">Book a Demo Now!</h2>
          </div>
          <div className="Flex-input-Box-form">
            <div className="input-name-book">
              <label className="lbl-acc-name-erp">Name</label>
              <input className="input-box-erpdemo"
                type="text"
                value={Emaildata.name}
                onChange={(e)=>handleChagne(e)}
                name="name"
                placeholder="Enter Name"
                required
              />
            </div>

            <div className="input-name-book">
              <label className="lbl-acc-name-erp">Email</label>
              <input className="input-box-erpdemo"
                type="email"
                value={Emaildata.email}
                onChange={(e)=>handleChagne(e)}
                name="email"
                placeholder="Enter Email"
                required
              />
            </div>
          </div>

          <div className="Flex-input-Box-form">
            <div className="input-name-book">
              <label className="lbl-acc-name-erp">Mobile Number</label>
              <input className="input-box-erpdemo"
                type="text"
                value={Emaildata.mobileno}
                onChange={(e)=>handleChagne(e)}
                name="mobileno"
                placeholder="Enter Mobile No"
                required
              />
            </div>

            <div className="input-name-book">
              <label className="lbl-acc-name-erp">Interested Products</label>
              <select className="interested-product"
                type="text"
                name="interestedProduct"
                onChange={(e)=>handleChagne(e)}
                value={Emaildata.interestedProduct}
                required
              >
                <option value={"Select Interested Product"}>Select Interested Product</option>
                <option value={"School Management Software"}>School Management Software</option>
                <option value={"College Management Software"}>College Management Software</option>
                <option value={"Training Institute Software"}>Training Institute Software</option>
                <option value={"University Management Software"}>University Management Software</option>
                <option value={"Other"}>Other</option>
              </select>
            </div>
          </div>

          <div className="Flex-input-Box-form">
            <div className="input-name-book">
              <label className="lbl-acc-name-erp">Business Name</label>
              <input className="input-box-erpdemo"
              value={Emaildata.businessname}
                type="text"
                onChange={(e)=>handleChagne(e)}
                name="businessname"
                placeholder="Enter Business Name"
                required
              />
            </div>

            <div className="input-name-book">
              <label className="lbl-acc-name-erp">Requirement</label>
              <input className="input-box-erpdemo"
                name="requirment"
                value={Emaildata.requirment}
                onChange={(e)=>handleChagne(e)}
                placeholder="Enter Requirement"
                required
              />
            </div>
          </div>
          <div className="flex-btn-submit">
            <button onClick={(e)=> handleFormSubmmit(e)}>Submit</button>
          </div>
          <div className="privacy-top-form">
            <p className="privacy-committed">Techcherry is committed to your privacy. By submitting this form, you acknowledge Techcherry uses your information in accordance with its Privacy Policy. You may unsubscribe from our communications at any time. To opt out, please email <span className="email-color">contact@techcherry.in</span></p>
          </div>
        </div>
      </div>

      <div className="Optform faq-content-edu">
          <h3 className="text-center text-4xl">
            <span className="text-amber-700"> FAQ`s </span>
          </h3>

          <div className="sub-optform">
            {qution.map((item, index) => {
              return (
                <>
                  <div className="optform1">
                    <p
                      className="bg-slate-300 cursor-pointer"
                      onClick={() => toggleDropdown(index)}
                    >
                      <span className="bg-slate-400">+</span> Q: {item.question}
                    </p>
                    {openSection === index && (
                      <div className="dropdown-content">
                        <p> A: {item.answer}</p>
                      </div>
                    )}
                  </div>
                </>
              );
            })}
          </div>
        </div>

    </div>
  );
};

export default Boxes;
